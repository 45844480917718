import React from 'react'
import styles from './index.module.scss'
import TikTok from '@/assets/TikTok.webp'
import Instagram from '@/assets/Instagram.webp'
import Pinterest from '@/assets/Pinterest.webp'
import Youtube from '@/assets/Youtube.webp'
import Logo from '@/assets/couponLogo.webp'
import dynamic from "next/dynamic";
import Image from "next/image";

const ListType13 = dynamic(() => import('@/components/List/type13'))

export default function Footer({footer}: any) {
  return (
    <div className={styles.footer}>
      <div className={styles.footer_container}>
        {footer && footer.about && (
          <ListType13
            name={"About"}
            links={[
              {name: "About Us", path: "/about-us"},
              {name: "Privacy Policy", path: "/privacy-policy"},
              {name: "Contact Us", path: "/contact-us"},
              {name: "Term of Use", path: "/term-of-use"},
            ]}
          ></ListType13>
        )}
      </div>
      <hr style={{borderColor: '#fff', borderBottom: '0'}} />
      {footer && footer.copyright && (
        <div className={styles.footer_bottom}>2024 powerpromocode.com. All rights reserved</div>
      )}
    </div>
  )
}
